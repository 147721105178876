<template>
  <div class="card main">
    <Carousel :value="orderList" :numVisible="3" :numScroll="3" :responsiveOptions="responsiveOptions" circular :autoplayInterval="3000">
      <template #item="slotProps">
        <div class="card margin10" style="background-color: #f9f9f9;">
          <div class="div_center"><a :href="slotProps.data.URL" target="_blank">{{ slotProps.data.NAME }}</a></div>
          <div class="div_title">
            <div>
              <img @click="click({type:'openImg', imgUrl:slotProps.data.IMG_URL})" :src="slotProps.data.IMG_URL" :alt="slotProps.data.NAME" class="link carousellImg" />
            </div>
            <div>
              <div>
                <Tag class="div_center" :value="slotProps.data.BRAND" severity="warn" />
              </div>
              <br>
              <div >
                <Tag class="div_center" :value="slotProps.data.COUNTRY" severity="success"/>
              </div>
              <br>
              <div class="div_center">{{ slotProps.data.ORIGIN_PRICE }} {{ slotProps.data.CURRENCY }}</div>
            </div>
          </div>
        </div>
      </template>
    </Carousel>
  </div>
</template>

<script>
import Carousel from 'primevue/carousel'
import { ref } from 'vue'

export default {
  setup() {
    const responsiveOptions = ref([
      // {
      //   breakpoint: '1400px',
      //   numVisible: 2,
      //   numScroll: 1
      // },
      {
        breakpoint: '1300px',
        numVisible: 3,
        numScroll: 1
      },
      {
        breakpoint: '900px',
        numVisible: 2,
        numScroll: 1
      },
      {
        breakpoint: '700px',
        numVisible: 1,
        numScroll: 1
      }
    ])
    return {
      responsiveOptions
    }
  },
  mounted() {
    this.search()
  },  
  components: {
    Carousel
  },
  data() {
    return {
      orderList:[]
    }
  },
  methods: {
    click(param) {
      const type = param.type
      if(type === 'openImg') {
        const imgUrl = param.imgUrl
        window.open(imgUrl, '_blank', 'width=800,height=600');
      }
    },
    async search() {
      this.api.axiosGql('SELECT_ORDER_IMG_URL', {type:'SELECT_ORDER_IMG_URL'})
      .then( (res) => {
        if(res.status === 200) {
          this.orderList = res.data
          this.orderList.map( (item) => {
            const country = item.COUNTRY
            let currency = ' EUR'
            if(country === '홍콩') currency = ' HKD'
            else if(country === '영국') currency = ' 파운드'
            else if(country === '미국') currency = ' USD'
            else if(country === '일본') currency = ' 엔'
            else if(country === '중국') currency = ' 위안화'
            else if(country === '싱가포르') currency = ' SGD'
            else if(country ==='호주') currency = ' AUD'
            else if(country ==='뉴질랜드') currency = ' NZD'
            else if(country ==='베트남') currency = ' VND'
            else if(country ==='캐나다') currency = ' CAD'
            else if(country ==='대만') currency = ' TWD'
            // else if(country ==='태국') currency = ' THB'
            else if(country === '기타') currency = ''
            item.CURRENCY = currency
          })
        } else this.orderList = []
      })
      .catch( (err) => {
        console.log(err)
        this.orderList = []
      }) 
    }
  }
}
</script>
<style scoped>
.carousellImg {
  max-height:120px;
  max-width:100px;
  border-radius:16px;
  object-fit: cover;
}
</style>