<template>
  <div class="div_title" >
    <span style="color: #ff7f50;">주문현황</span> 
    <span>
      <Button label="조회" v-on:click="refresh" raised size="small" style="float:right;" ></Button>
      <select v-model="date" class="select-keyword">  <!-- 카카오 ID로 검색 -->
        <option value = "r1">최근 1개월</option>
        <option value = "r2">최근 3개월</option>
        <option value = "r3">최근 6개월</option>
        <option value = "r4">최근 1년</option>
      </select>
    </span>
  </div>
  <dashboard page="home" class="card main" :key='dashboardKey' @login="this.$emit('login')" />
  <div class="div_title" ><span style="color: #ff7f50;">예상견적</span> </div>
  <estimate1 class="card main" id="estimate_section" />
  <div class="div_title"><span style="color: #ff7f50;">실제 구매대행비 조회 - <span style="font-weight: normal; font-size:11px;"><a href="https://www.carousell.com.hk/" target="_blank">CAROUSELL</a> / <a href="https://www.vinted.fr/" target="_blank">VINTED</a>  / <a href="https://merci-merci.com/" target="_blank">MERCI</a>  / <a href="https://shopee.sg/" target="_blank">SHOPEE</a> 전용</span></span> </div>
  <div class="card main"><estimate2 /></div>
  <div class="div_title"><span style="color: #ff7f50;">실제 배송대행비 조회 - <span style="font-weight: normal; font-size:11px;">다른 구매자님의 상품 별 실제 배송비 결제 내역</span></span> </div>
  <div class="card main"><estimate3 /></div>  
  <div class="div_title"><span style="color: #ff7f50;">필독</span></div>
  <faq id="faq_section" />
  <div class="div_title">
    <span style="color: #ff7f50;">진행중/후기</span>
    <span class="link" style="font-size: 12px;" @click="router('review')">더보기</span>
  </div>
  <ordercarousel id="ordercarousell_section" />
  <br>
  <div class="ad-container">
    <Adsense
      data-ad-client="ca-pub-6699354447815429" 
      data-ad-slot="8230887469"
      data-ad-format="horizontal"
      data-full-width-responsive="true">
    </Adsense>
  </div>
  <img src="./assets/logo_big.jpg" style="display: none;" alt="geek9.kr">
</template>

<script>
import dashboard from './components/home/dashboard.vue'
import estimate1 from './components/home/estimate1.vue'
import estimate2 from './components/home/estimate2.vue'
import estimate3 from './components/home/estimate3.vue'
import ordercarousel from './components/home/ordercarousel.vue'
import faq from './components/home/faq.vue'
import ssr from './components/common/ssr'
import { inject } from 'vue'
import router from './router'

// import { useMeta } from 'vue-meta'

export default {
  emits: ["setCookies", "setMenu", "login", "logout"],
  setup() {
    const setMeta = inject('setMeta')
    
    setMeta({
      title: 'GEEK9 - 한방에 직구하기',
      keywords: '직구,해외직구,구매대행,배송대행,배대지,해외운송,이사,geek9,3PL,풀필먼트',
      description: '해외직구/구매대행/배송대행/결제대행/해외운송/이사/3PL',
      'og:title': 'GEEK9 - 한방에 직구하기',
      'og:description': '해외직구/구매대행/배송대행/결제대행/해외운송/이사/3PL',
      'og:image': `${ssr.GEEK9_URL}img/logo_big.e0186dc8.jpg`,
      'og:url': ssr.GEEK9_URL,
      'canonical': ssr.GEEK9_URL
    })

    const addJsonLd = () => {
      let flag = true
      document.querySelectorAll('script').forEach(el => {
        if (el.getAttribute('type') === 'application/ld+json' ) {
          flag = false
          return
        }
      })
      if(flag === true) {
        const script = document.createElement('script')
        script.setAttribute('type', 'application/ld+json')
        script.textContent = JSON.stringify(
          {
            "@context": "https://schema.org",
            "@graph": [
              ssr.getWebsite(ssr.GEEK9_URL, "GEEK9 - 한방에 직구하기", "GEEK9 - 해외기업물류/개인해외직구"),
              ssr.getOrganization(ssr.GEEK9_URL, "GEEK9"),
              ssr.getWebPage(ssr.GEEK9_URL, "GEEK9 - 한방에 직구하기", ssr.GEEK9_URL, "글로벌 물류대행 업체 GEEK9 - 구매대행/배송대행/결제대행/수출/수입/통관/3PL/풀필먼트"),
              {
                "@type": "BreadcrumbList",
                "name": "GEEK9 주요 서비스",
                "itemListElement": [{
                  "@type": "ListItem",
                  "name":"GEEK9 > 해외직구도우미",
                  "position": 1,
                  "item":{"@id":ssr.GEEK9_URL+"helper#webPage"} 
                },{
                  "@type": "ListItem",
                  "name":"GEEK9 > 해외중고샵",
                  "position": 2,
                  "item": {"@id":ssr.GEEK9_URL+"shop#webPage"} 
                },{
                  "@type": "ListItem",
                  "name":"GEEK9 > 문의",
                  "position": 3,
                  "item": {"@id":ssr.GEEK9_URL+"qna#webPage"}
                },{
                  "@type": "ListItem",
                  "name":"GEEK9 > 게시판",
                  "position": 4,
                  "item": {"@id":"https://contents.geek9.kr/#webPage"} 
                },{
                  "@type": "ListItem",
                  "name":"GEEK9 > 기업물류",
                  "position": 5,
                  "item": {"@id":ssr.GEEK9_URL+"biz#webPage"} 
                }]
              }
            ]
          }
        )
        document.head.appendChild(script)
      }
    }
    return {
      addJsonLd
    }
  },
  components: {
    dashboard, estimate1, estimate2, estimate3, faq, ordercarousel
  },
  mounted() {
    this.addJsonLd()
    let menuType = this.$route.params.type
    if(menuType !== undefined && menuType !== ''){
      const el = document.getElementById(menuType)
      el.scrollIntoView(el.scrollTop)
      el.setAttribute('class', 'card main blink')
      if(menuType === 'estimate_section') this.$emit("setMenu", {page: menuType})
    }
    this.api.setPage('home')
    console.log(this.api.getCookies('page'))
    this.emitter.on('homeReload', this.reload)

    if(window.$cookies.get('login') === 'true') {
      document.getElementById('user_name').innerHTML = window.$cookies.get('nickname')
      document.getElementById('user_pic').src = window.$cookies.get('img')
    }
    this.emitter.emit('refresh' + this.api.getCookies('page'), {range: this.date})
  },
  unmounted() {
    this.emitter.off('homeReload', this.reload)
  },
  data(){
    return{
      date: 'r2',
      dashboardKey: 0
    }
  },
  methods : {
    router(page) {
      router.push({name:page})
    },
    reload(flag) {
      if(flag === 'dashboard') {
        this.dashboardKey += 1
      }
      this.refresh()
    },
    async refresh() {
      this.emitter.emit('refresh' + this.api.getCookies('page'), {range: this.date})
    },
    // async request() {
    //   window.Kakao.API.request({
    //     url: '/v2/user/me',
    //     success: async (response) => {
    //       let flag = false
    //       await this.api.axiosGql('SELECT_COMMON_USER', {type: 'SELECT_COMMON_USER', id:response.id })
    //       .then( async (res) => {
    //         const result = res.data
    //         if(result === 'null' || result === undefined || result === null ) flag = false
    //         else flag = true
    //       })
    //       .catch((err) => {
    //         console.log(err)
    //       })
    //       if(flag) {
    //         this.emitter.emit("setCookies", {login:true, id:response.id, nickname:response.properties.nickname, img:response.properties.thumbnail_image, mail:response.kakao_account.email})
    //       } 
    //       // else this.showRegistModal(response.id)
    //     },
    //     fail: (error) => {
    //       this.$notify("다시 시도해주세요.")
    //       console.log(error)
    //     }
    //   })
    // },
    // async login() {
      
      // window.Kakao.Auth.login({
      //   success: () => {
      //     this.tmp = '로그인'
      //     this.request()
      //   },
      //   fail: () => {
      //     this.$notify("다시 시도해주세요.")
      //   },
      // })
    // },
    // logout() {
      //   this.emitter.emit("setCookies", {login:false, id:'', nickname:'',img:'', mail:'', comnum:'', comname:''})
      //   this.tmp = 'not'
      //   this.$notify('로그아웃 되었습니다.')
      // })
    // },
  }
}
</script>

<style>
* {
  scroll-behavior: smooth;
}
@keyframes blink-effect {
  50% {
    opacity: 0;
  }
}
.blink {
  animation: blink-effect 1s step-end 4;
}

</style>
